import { wrapRootElement } from './wrapRootElement'
import { pageViewed } from './src/lib/segmentEvents'

const onRouteUpdate = ({ location, prevLocation }) => {
  // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page
  // timeout needed to give react-helmet time to re-render with the new page title
  setTimeout(() => pageViewed(prevLocation), 100)
}

export { wrapRootElement, onRouteUpdate }
