import React from 'react'

const SettingsContext = React.createContext({
  units: 'metric',
  toggleUnits: () => '',
})

class SettingsProvider extends React.Component {
  state = {
    units: 'standard',
  }
  toggleUnits = () => {
    let newUnits = this.state.units === 'standard' ? 'metric' : 'standard'
    localStorage.setItem('units', JSON.stringify(newUnits))
    this.setState({ units: newUnits })
  }
  setUnits = newUnits => {
    localStorage.setItem('units', JSON.stringify(newUnits))
    this.setState({ units: newUnits })
  }
  componentDidMount() {
    const lsUnits = JSON.parse(localStorage.getItem('units'))
    if (lsUnits) {
      this.setState({ units: lsUnits })
    }
  }
  render() {
    const { children } = this.props
    const { units } = this.state
    return (
      <SettingsContext.Provider
        value={{
          units,
          toggleUnits: this.toggleUnits,
          setUnits: this.setUnits,
        }}
      >
        {children}
      </SettingsContext.Provider>
    )
  }
}
export default SettingsContext
export { SettingsProvider }
